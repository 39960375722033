const firebaseConfig = {
    apiKey: "AIzaSyDoWVXaEhYI35QnmzDKfgULhwW2Kv8eFb4",
    authDomain: "add4cart.firebaseapp.com",
    databaseURL: "https://add4cart-default-rtdb.firebaseio.com",
    projectId: "add4cart",
    storageBucket: "add4cart.appspot.com",
    messagingSenderId: "386327114853",
    appId: "1:386327114853:web:7d20a9b3d1ddba5ae8028c",
    measurementId: "G-EK3B6BKGM1",
    vapidKey:"BKQ9bmYlisdKDn0fbeZXHnXCsiOywCOqQNLTS1eOEyYOjQKX2gQ7rZALmBP5aRp5ia-VTgUTpRJkMrumeCCY-pw"
};

export default firebaseConfig;